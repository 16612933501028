import styled from "styled-components";

export const Privacy = () => (
  <StyledPage>
    <h1 className="title">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h1>
    <p>
      Ми розуміємо всю важливість конфіденційності. Тому ми розробили це
      Положення з метою пояснити наші правила, що стосуються персональної
      інформації, отриманої в усній чи письмовій формі або наданої при
      відвідуванні даного веб-сайту або з інших джерел.
    </p>
    <p>
      Згідно Закону України «Про захист персональних даних», заповнюючи дані
      реєстраційної форми та проставляючи відмітку про ознайомлення з даною
      Політикою конфіденційності та обробки персональних даних (далі – Політика
      конфіденційності) Ви даєте згоду на обробку Ваших персональних даних в
      цілях та в умовах, передбачених цією політикою.
    </p>
    <p>
      Ми цінуємо Ваше право на особисте життя та нерозголошення Ваших
      персональних даних. Ця Політика конфіденційності – правила, якими
      користуються всі співробітники нашої компанії, та регламентує збір і
      використання персональних даних.
    </p>
    <div className="subtitle">ТЕРМІНИ ТА ВИЗНАЧЕННЯ</div>
    <p>
      Автоматизована обробка персональних даних - обробка персональних даних за
      допомогою комп'ютерних засобів;
    </p>
    <p>
      Блокування персональних даних - тимчасове припинення обробки персональних
      даних (крім випадків, коли обробка необхідна для уточнення персональних
      даних);
    </p>
    <p>
      Система персональних даних - набір персональних даних, що містяться в
      базах даних та забезпечують їх обробку інформаційними технологіями та
      технічними засобами;
    </p>
    <p>
      Обробка персональних даних - будь-яка дія або сукупність дій, таких як
      збирання, реєстрація, накопичення, зберігання, адаптування, зміна,
      поновлення, використання і поширення (розповсюдження, реалізація,
      передача), знеособлення, знищення персональних даних, у тому числі з
      використанням інформаційних (автоматизованих) систем;
    </p>
    <p>
      Володілець персональних даних - фізична або юридична особа, яка визначає
      мету обробки персональних даних, встановлює склад цих даних та процедури
      їх обробки, якщо інше не визначено законом;
    </p>
    <p>
      Суб’єкт персональних даних - фізична особа, персональні дані якої
      обробляються;
    </p>
    <p>
      Згода суб’єкта персональних даних - добровільне волевиявлення фізичної
      особи (за умови її поінформованості) щодо надання дозволу на обробку її
      персональних даних відповідно до сформульованої мети їх обробки,
      висловлене у письмовій формі або у формі, що дає змогу зробити висновок
      про надання згоди. У сфері електронної комерції згода суб’єкта
      персональних даних може бути надана під час реєстрації в
      інформаційно-комунікаційній системі суб’єкта електронної комерції шляхом
      проставлення відмітки про надання дозволу на обробку своїх персональних
      даних відповідно до сформульованої мети їх обробки, за умови, що така
      система не створює можливостей для обробки персональних даних до моменту
      проставлення відмітки;
    </p>
    <p>
      Персональні дані - відомості чи сукупність відомостей про фізичну особу,
      яка ідентифікована або може бути конкретно ідентифікована;
    </p>
    <p>
      Одержувач - фізична чи юридична особа, якій надаються персональні дані, у
      тому числі третя особа;
    </p>
    <p>
      Третя особа - будь-яка особа, за винятком суб’єкта персональних даних,
      володільця чи розпорядника персональних даних та Уповноваженого Верховної
      Ради України з прав людини, якій володільцем чи розпорядником персональних
      даних здійснюється передача персональних даних;
    </p>
    <p>
      Надання персональних даних - дії, спрямовані на розкриття персональних
      даних одержувачу;
    </p>
    <p>
      Розпорядник персональних даних - фізична чи юридична особа, якій
      володільцем персональних даних або законом надано право обробляти ці дані
      від імені володільця;
    </p>
    <p>
      Поширення персональних даних - дії, спрямовані на передачу відомостей про
      фізичну особу за згодою суб'єкта персональних даних;
    </p>
    <p>
      Транскордонне передавання персональних даних - передача персональних даних
      на територію іноземної держави у повноваження іноземної держави, іноземній
      фізичній або іноземній юридичній особі;
    </p>
    <p>
      Знищення персональних даних - це дія, яка унеможливлює відновлення вмісту
      персональних даних в інформаційній системі персональних даних та (або), що
      призводить до знищення матеріальними носіями персональних даних;
    </p>
    <p>
      Користувач - означає особу, яка уклала Угоду з HH в передбаченому порядку.
    </p>
    <p>
      Ця політика конфіденційності персональної інформації (далі — Політика)
      стосується всієї інформації, яка доступна Фізична особа підприємець
      Мицавка Юрій Юрійович, що може бути отримана про користувача під час його
      використання будь-якого з сайтів House Hunter, сервісів, програм і
      продуктів (далі - послуги, HH Services).
    </p>
    <p>
      Використання служб HH означає, що користувач беззастережно погоджується на
      цю політику і на умови обробки його персональної інформації, що міститься
      в ній; у разі незгоди з цими умовами користувач повинен утриматися від
      використання послуг.
    </p>
    <p>
      Положення цієї політики є основою для організації роботи з обробки
      персональних даних від одержувача, включаючи розробку внутрішніх
      нормативних документів, що регулюють обробку та захист персональних даних
      від одержувача.
    </p>
    <p>
      Якщо певні положення цієї політики суперечать чинному законодавству щодо
      персональних даних, то застосовуються положення чинного законодавства.
    </p>
    <p>
      Суб'єкти персональних даних можуть подавати свій запит, заявку, вимогу із
      застосуванням даних, що дозволяють ідентифікувати суб'єкта або
      підписуватися кваліфікованим електронним цифровим підписом на адресу
      електронної пошти: 
      <a target="_blank" href="https://house-hunter.info//info/contact">
        https://house-hunter.info//info/contact
      </a>
      .  
    </p>
    <p>
      Строк розгляду запиту, заявки, вимоги не перевищує 30 (тридцяти) днів з
      дня подання заяви.
    </p>
    <div className="subtitle">
      1. ІНФОРМАЦІЯ ПРО КОРИСТУВАЧА, ЯКУ ОТРИМУЄ ТА ОБРОБЛЯЄ HOUSE HUNTER
    </div>
    <p>1.1. У цій політиці термін «інформація про користувача» означає:</p>
    <p>
      1.1.1. Інформація, що користувач надає про себе під час реєстрації
      (створення облікового запису), авторизації або під час користування
      послугами, включаючи персональні дані користувача: прізвище, ім'я, по
      батькові, номери контактних телефонів, адреси електронної пошти, місце
      роботи. Інформація, необхідна для надання послуг, відзначається особливим
      чином. Інша інформація, яка надана на розсуд користувача.
    </p>
    <p>
      Діючи від імені компанії, користувач надає інформацію про таку компанію:
      назву, реєстраційні та інші ідентифікаційні номери, перелік послуг, що
      надаються, адреси, номери телефонів та факсів, веб-сайт (и), банківські
      реквізити тощо. Інформація про компанію не складає персональних даних.
    </p>
    <p>
      1.1.2. Дані, які автоматично передаються до служб HH під час їх
      використання за допомогою програмного забезпечення, встановленого на
      пристрої користувача, включаючи IP-адресу, інформацію про cookie,
      інформацію про браузер користувача (або будь-яку іншу програму, що надає
      доступ до служб), час доступу, адресу сторінки.
    </p>
    <p>
      1.1.3. Інші відомості про користувача, збір та/або надання яких визначено
      в Регулюючих документах окремих служб HH.
    </p>
    <p>
      1.2. Ця політика поширюється лише на послуги HH. HH не контролює або несе
      відповідальність за будь-які сторонні сайти, на які користувач може
      отримати доступ до посилань, доступних на сайтах HH, включаючи результати
      пошуку. На таких сайтах інша персональна інформація може бути зібрана або
      запитана від користувача, а також вчинятися інші дії.
    </p>
    <p>
      1.3. HH, як правило, не перевіряє достовірність інформації, що надається
      користувачами, а також не стежить за їх юридичною спроможністю.
      Однак, HH припускає, що користувач надає достовірну і достатню інформацію
      з питань, що пропонуються в реєстраційній формі, і зберігає цю інформацію
      в теперішньому часі. Наслідки надання неправдивої інформації визначені в
      Угоді користувача послуг HH 
      <a target="_blank" href="https://house-hunter.info//info/terms">
        https://house-hunter.info//info/terms
      </a>
      .
    </p>
    <p>
      1.4. Приймаючи цю політику, а також застосовуючи певні налаштування у
      своєму програмному забезпеченні, включаючи налаштування операційної
      системи пристрою, користувач погоджується з умовами збору та використання
      даних постачальниками вищезазначених програмних послуг.
    </p>
    <p>
      Cookies - це невеликі текстові файли, що записуються на пристрій
      користувача і містять інформацію про дії користувача в мережі Інтернет.
      Деякі з цих файлів служать для визначення місця, інші - для збереження
      параметрів користувача.
    </p>
    <p>
      Користувач на свій розсуд налаштовує в браузері користувача параметри
      використання cookie. Однак не рекомендується вимикати використання всіх
      cookies, оскільки в такому випадку користувач не зможе використовувати
      деякі функціональні можливості платформи.
    </p>
    <div className="subtitle">
      2. МЕТА ЗБОРУ ТА ОБРОБКИ ІНФОРМАЦІЇ ПРО КОРИСТУВАЧА
    </div>
    <p>
      2.1. HH збирає і зберігає тільки ті дані, які необхідні для використання
      служб і надання послуг (виконання договорів і угод з користувачем).
    </p>
    <p>
      2.2. Персональну інформацію користувача HH можна використовувати для
      наступних цілей:
    </p>
    <p>2.2.1. Ідентифікація сторони в рамках договорів і угод з HH;</p>
    <p>
      2.2.2. Надання користувачу персоналізованих послуг, демонстраційний доступ
      до послуг;
    </p>
    <p>
      2.2.3. Зв'язок з користувачем, включаючи надсилання сповіщень, запитів та
      інформації щодо використання служб, надання послуг, а також обробку
      запитів і заявок від користувача; Відправлення рекламних, новинних,
      інформаційних повідомлень (розсилок), а також Push-сповіщень, що
      стосуються використання служб, надання послуг, а також обробки запитів і
      заявок від користувача;
    </p>
    <p>
      2.2.4. Підвищення якості послуг, зручність їх використання, розвиток нових
      служб і послуг;
    </p>
    <p>2.2.5. Таргетування рекламних матеріалів;</p>
    <p>
      2.2.6. Статистичні та інші дослідження на основі анонімізованих даних.
    </p>
    <div className="subtitle">
      3. УМОВИ ОБРОБКИ ІНФОРМАЦІЇ КОРИСТУВАЧА ТА ЇЇ ПЕРЕДАЧІ ТРЕТІМ ОСОБАМ
    </div>
    <p>
      3.1. HH зберігає інформацію про користувача відповідно до внутрішніх
      правил конкретних служб.
    </p>
    <p>
      3.2. Інформація користувача зберігається конфіденційно, за винятком
      випадків, коли користувач добровільно надає інформацію про себе для
      публічного доступу для необмеженої кількості осіб. При використанні
      індивідуальних послуг користувач погоджується, що певна частина його
      інформації стає публічною.
    </p>
    <p>
      3.3. HH може обмінюватися інформацією про користувача з третіми особами у
      таких випадках:
    </p>
    <p>3.3.1. Користувач висловив свою згоду на такі дії;</p>
    <p>
      3.3.2. Передача потрібна в рамках використання користувачем певної служби
      або надання послуги користувачеві;
    </p>
    <p>
      3.3.3. Передача передбачена українським або іншим чинним законодавством у
      порядку, встановленому законом;
    </p>
    <p>
      3.3.4. Ця передача відбувається в рамках продажу або іншої передачі
      бізнесу (повністю або частково), з усіма зобов'язаннями дотримуватися умов
      цієї політики щодо персональної інформації, отриманої покупцем;
    </p>
    <p>
      3.3.5. З метою захисту прав та законних інтересів HH або третіх осіб у
      випадках, коли користувач порушує Угоду користувача HH.
    </p>
    <p>
      3.4. При обробці даних користувачів, HH керується Законом України «Про
      захист персональних даних»:
    </p>
    <p>
      3.4.1. Під час обробки персональних даних користувача будуть здійснені
      наступні дії: збір, запис, систематизація, накопичення, зберігання,
      уточнення (оновлення, модифікація), вилучення, використання, блокування,
      видалення, утилізація, передача (доручення, доступ).
    </p>
    <p>
      3.4.2. Персональні дані користувача обробляються до тих пір, поки не буде
      досягнуто мети обробки персональних даних або відкликано згоду на обробку
      персональних даних, в залежності від того, яка з дій відбудеться раніше.
    </p>
    <p>
      3.4.3. Користувач має право відкликати свою згоду на обробку персональних
      даних, подавши заявку до HH відповідно до законодавства України. У разі
      анулювання згоди на обробку персональних даних, HH має право продовжувати
      обробку персональних даних, якщо є підстави, передбачені Законом України
      «Про захист персональних даних».
    </p>
    <p>
      3.4.4. Згода на обробку персональних даних діє весь час до припинення
      обробки персональних даних, зазначених у 3.4.2. цієї Політики.
    </p>
    <div className="subtitle">4. ПРАВА СУБ'ЄКТА ПЕРСОНАЛЬНИХ ДАНИХ</div>
    <p>
      4.1. Особисті немайнові права на персональні дані, які має кожна фізична
      особа, є невід'ємними і непорушними.
    </p>
    <p>
      4.2. Суб'єкт персональних даних має право отримувати від одержувача
      інформацію, що стосується обробки його персональних даних: про джерела
      збирання, місцезнаходження своїх персональних даних, мету їх обробки,
      місцезнаходження або місце проживання (перебування) володільця чи
      розпорядника персональних даних або дати відповідне доручення щодо
      отримання цієї інформації уповноваженим ним особам, крім випадків,
      встановлених законом та якщо таке право не обмежено відповідно до законів
      України.
    </p>
    <p>
      4.3. Суб'єкт персональних даних має право на доступ до своїх персональних
      даних та отримувати інформацію про умови надання доступу до персональних
      даних, зокрема інформацію про третіх осіб, яким передаються його
      персональні дані.
    </p>
    <p>
      4.4. Суб'єкт персональних даних має право пред'являти вмотивовану вимогу
      одержувачу щодо зміни або знищення своїх персональних даних будь-яким
      володільцем та розпорядником персональних даних, якщо ці дані обробляються
      незаконно чи є недостовірними.
    </p>
    <p>
      4.5. Суб'єкт персональних даних має право на захист своїх персональних
      даних від незаконної обробки та випадкової втрати, знищення, пошкодження у
      зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а
      також на захист від надання відомостей, що є недостовірними чи ганьблять
      честь, гідність та ділову репутацію фізичної особи.
    </p>
    <p>
      4.6. Суб'єкт персональних даних має право вносити застереження стосовно
      обмеження права на обробку своїх персональних даних під час надання згоди;
      пред’являти вмотивовану вимогу володільцю персональних даних із
      запереченням проти обробки своїх персональних даних; відкликати згоду на
      обробку персональних даних; застосовувати засоби правового захисту в разі
      порушення законодавства про захист персональних даних.
    </p>
    <p>
      4.7. Обробка персональних даних з метою просування товарів, робіт, послуг
      на ринку за допомогою прямих контактів з суб'єктом персональних даних
      (потенційним споживачем) за допомогою зв'язку, а також в цілях політичної
      агітації допускається лише за попередньої згоди суб'єкта персональних
      даних.
    </p>
    <p>
      4.8. Одержувач зобов'язаний негайно припинити обробку своїх персональних
      даних для зазначених цілей на вимогу суб'єкта персональних даних.
    </p>
    <p>
      4.9. Забороняється приймати рішення на основі виключно автоматизованої
      обробки персональних даних, які мають правові наслідки для суб'єкта
      персональних даних або іншим чином впливають на його права та законні
      інтереси, крім випадків, які передбачені законами України, або з письмової
      згоди суб'єкта персональних даних.
    </p>
    <p>
      4.10. Якщо суб'єкт персональних даних вважає, що одержувач здійснює
      обробку його персональних даних з порушенням вимог законодавства України
      або в іншому випадку порушує його права і свободи, суб'єкт персональних
      даних має право оскаржити дії або бездіяльність одержувача Уповноваженому
      органу з захисту прав суб'єктів персональних даних або в суді.
    </p>
    <p>
      4.11. Суб'єкт персональних даних має право на захист своїх прав і законних
      інтересів, включаючи відшкодування збитків і (або) відшкодування моральної
      шкоди.
    </p>
    <div className="subtitle">
      5. ЗМІНА КОРИСТУВАЧЕМ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ
    </div>
    <p>
      5.1. Користувач може в будь-який час змінити (оновити, додати) інформацію
      або її частину, а також її параметри конфіденційності за допомогою функції
      редагування даних в розділі «особистий кабінет» або в особистому розділі
      відповідної служби.
    </p>
    <p>
      5.2.  Володільці чи розпорядники персональних даних зобов'язані вносити
      зміни до персональних даних на підставі вмотивованої письмової вимоги
      суб'єкта персональних даних, а також за зверненням інших суб’єктів
      відносин, пов’язаних із персональними даними, якщо на це є згода суб’єкта
      персональних даних чи відповідна зміна здійснюється згідно з приписом
      Уповноваженого або визначених ним посадових осіб секретаріату
      Уповноваженого чи за рішенням суду, що набрало законної сили.
    </p>
    <p>
      5.3. Користувач також може видалити надану ним інформацію в межах певного
      облікового запису, звернувшись до HH будь-яким зручним способом. Видалення
      облікового запису може призвести до неможливості використання деяких
      служб HH.
    </p>
    <div className="subtitle">
      6. ЗАХОДИ, ЩО ЗАСТОСОВУЮТЬСЯ ДЛЯ ЗАХИСТУ ПЕРСОНАЛЬНИХ ДАНИХ КОРИСТУВАЧІВ
    </div>
    <p>
      6.1. HH вживає необхідних і достатніх організаційно-технічних заходів для
      захисту інформації користувача від несанкціонованого або випадкового
      доступу, знищення, зміни, блокування, копіювання, розподілу, а також від
      інших протиправних дій з ними третіми особами.
    </p>
    <p>6.2. ТЕЛЕФОННІ ДЗВІНКИ</p>
    <p>
      Можуть бути записані дзвінки до Служби підтримки користувачів HH та
      дзвінки із Служби підтримки користувачів. Це необхідно для обробки ваших
      даних, як зазначено в частині 2.1. цієї Політики. Здійснюючи дзвінок
      на HH  або отримуючи дзвінок від HH і продовжуючи розмову з
      співробітником HH, ви погоджуєтеся на обробку наданих вами даних за
      умовами цієї політики.
    </p>
    <div className="subtitle">7. КОНФІДЕНЦІЙНА ІНФОРМАЦІЯ</div>
    <p>
      7.1. Поняття «конфіденційна інформація» означає відомості, пов'язані з
      Вашою расовою приналежністю або національністю, політичними поглядами,
      релігійними або філософськими переконаннями, участю в профспілках, станом
      здоров'я, сексуальним життям і орієнтацією, генетикою, кримінальним
      минулим, а також будь-які унікальні біометричні дані, що використовуються
      в цілях ідентифікації. У деяких юрисдикціях номера мобільних телефонів,
      дані про місце розташування та інформація на документах, що засвідчують
      особу, також є конфіденційною інформацією. Зазвичай HH не збирає
      конфіденційну інформацію, якщо вона не надається Вами добровільно або якщо
      від нас це не потрібно відповідно до чинних законів або правил.
    </p>
    <div className="subtitle">8. ОСОБИСТА ІНФОРМАЦІЯ, ОТРИМАНА ВІД ДІТЕЙ</div>
    <p>
      8.1. HH не збирає навмисно особисту інформацію про осіб молодше 18 років.
      Якщо Ви є батьками або опікуном, не дозволяйте своїм дітям надавати
      особисту інформацію без Вашого дозволу.
    </p>
    <div className="subtitle">9. ЗМІНА ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</div>
    <p>
      9.1. HH має право вносити зміни до цієї Політики конфіденційності. При
      внесенні змін до поточної версії вказується дата останнього оновлення.
      Нова версія політики набирає чинності з моменту її розміщення, якщо інше
      не передбачено новою версією політики. Поточна редакція завжди знаходиться
      на веб-сайті за посиланням https://house-hunter.info//info/privacy. 
    </p>
    <div className="subtitle">10. ВІДГУКИ, ПИТАННЯ ТА ПРОПОЗИЦІЇ</div>
    <p>
      Будь-які пропозиції або питання щодо цієї політики повинні бути
      повідомлені службі підтримки
      користувачів HH https://house-hunter.info//info/contact. 
    </p>
  </StyledPage>
);

const StyledPage = styled.div`
  height: 100vh;
  overflow: auto;
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  a {
    color: var(--main-color);
  }
  .title {
    color: var(--main-color);
    font-family: Overpass;
    font-size: 32px;
    font-style: normal;
    font-weight: var(--font-weight-200);
    line-height: 118%;
    letter-spacing: 0.64px;
    text-align: center;
  }
  .subtitle {
    margin: 20px 0;
    color: var(--main-color);
    font-family: Overpass;
    font-size: 20px;
    font-style: normal;
    font-weight: var(--font-weight-100);
    line-height: 118%;
    letter-spacing: 0.4px;
  }
  p {
    margin-top: 7px;
    color: var(--white-color);
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: var(--font-weight-100);
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 0 26px 0 0;
  }
`;
